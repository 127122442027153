/**
 * Ant Design style overrides.
 */

$box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$round: 6px;

.ant-input-number {
  border-radius: $round;

  .ant-input-number-handler-wrap {
    border-top-right-radius: $round;
    border-bottom-right-radius: $round;
  }
}

.ant-table-row {
  &:hover {
    cursor: pointer !important;
  }
}

.ant-typography {
  // font-weight: 700 !important;
  color: #1a1f36 !important;
}

.ant-button {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px !important;
}

.material {
  box-shadow: $box-shadow;
  border-radius: $round;
}

.ant-alert {
  border-radius: $round;
}

.ant-modal-header {
  border-top-left-radius: $round !important;
  border-top-right-radius: $round !important;
}

.ant-modal-content {
  border-radius: $round !important;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px !important;
}

.ant-tabs {
  border-radius: $round !important;
}

.ant-tag {
  border-radius: $round !important;
}

.ant-pagination-item {
  border-radius: $round !important;
}

.ant-input-password {
  border-radius: $round !important;
}

.ant-input {
  border-radius: $round !important;
}

.ant-picker {
  border-radius: $round !important;
}

.ant-select-selector {
  border-radius: $round !important;
}

// /**
//  * Extends
//  */
// .materialize-style {
//   box-shadow: $box-shadow;
//   transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
// }

// .is-round {
//   border-radius: none !important;
// }

// /**
//  * Style overrides
//  */
// .ant-page-header {
//   @extend .materialize-style;
//   @extend .is-round;
// }

// .ant-table {
//   @extend .materialize-style;
//   @extend .is-round;
//   box-shadow: $box-shadow;
// }

// .ant-table-container table > thead > tr:first-child th:first-child {
//   border-top-left-radius: 10px;
//   border-top-right-radius: 10px;
// }

// .ant-table-thead > tr > th {
//   border-top-left-radius: 10px;
//   border-top-right-radius: 10px;
// }

// .ant-table-row .ant-table-row-level-0 {
//   .ant-table-cell .ant-table-row-expand-icon-cell {
//     @extend .is-round;
//   }
// }

// .ant-tag {
//   @extend .is-round;
// }

// .ant-card {
//   @extend .materialize-style;
//   @extend .is-round;

//   .ant-card-actions {
//     border-radius: 0 0 10px 10px;
//   }
// }

// .ant-btn {
//   @extend .materialize-style;
// }

// .ant-modal {
//   .ant-modal-header {
//     border-radius: 10px 10px 0 0;
//   }

//   .ant-modal-content {
//     @extend .is-round;
//   }
// }

// .ant-page-header-ghost {
//   padding: 0 20px 0 20px !important;
// }

// .ant-pagination-next .ant-pagination-disabled {
//   @extend .is-round;
// }

// .ant-select-selector {
//   // @extend .materialize-style;
//   @extend .is-round;
// }

// .ant-select-dropdown {
//   @extend .is-round;
// }

// input {
//   @extend .is-round;
// }

// .ant-checkbox-input {
//   @extend .is-round;
// }

// .ant-picker {
//   @extend .is-round;
// }

// // Calendar dropdown
// .ant-picker-panel-container {
//   @extend .is-round;
// }

// .ant-picker-dropdown {
//   @extend .materialize-style;
//   @extend .is-round;
// }

// .ant-pagination-item {
//   @extend .is-round;
// }

// .ant-pagination-prev {
//   @extend .is-round;
// }

// .ant-pagination-item-link {
//   @extend .materialize-style;
//   @extend .is-round;
// }

// .ant-input-password {
//   @extend .is-round;
// }

// button {
//   @extend .is-round;
// }

// .ant-input-number {
//   @extend .is-round;
// }

// .ant-input-number-handler-wrap {
//   border-top-right-radius: 10px;
//   border-bottom-right-radius: 10px;

//   .ant-input-number-handler-up {
//     border-top-right-radius: 10px;
//   }

//   .ant-input-number-handler-down {
//     border-bottom-right-radius: 10px;
//   }
// }

// .ant-input {
//   @extend .is-round;
// }

// .ant-message-notice-content {
//   @extend .is-round;
// }

// .ant-dropdown {
//   @extend .material;
// }

//.ant-dropdown-menu {
//@extend .material;
//}

// .ant-dropdown-menu-item {
//   border-radius: 10px;
// }

// .ant-dropdown-menu-item,
// .ant-dropdown-menu-submenu-title {
//   padding: 12px 12px !important;
// }

// .ant-popover-inner {
//   @extend .is-round;
// }

// // Vertical nav menu sub pop-up
// .ant-menu {
//   @extend .is-round;
// }

// .ant-menu .ant-menu-sub {
//   @extend .is-round;
// }
