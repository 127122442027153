@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './overrides.scss';

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// $card-box-shadow: 0 0 0 1px #e3e8ee;
// $sm-box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3), 0 1px 2px 0 rgba(60, 64, 67, 0.15);
$card-box-shadow: 0 2px 10px #1b1b1b0a,0 5px 16px #1b1b1b0a;
$sm-box-shadow: 0 2px 10px #1b1b1b0a,0 5px 16px #1b1b1b0a;
$med-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$round: 10px;

h1, h2, h3, h4, h5, h6 {
  color: #1A1F36 !important;
}

.box-shadow {
  box-shadow: $card-box-shadow;
}

.sm-box-shadow {
  box-shadow: $sm-box-shadow;
}

.med-box-shadow {
  box-shadow: $med-box-shadow;
}

.round {
  border-radius: $round;
}

#globalSidebar {
  background-color: #fff;
  max-width: 250px !important;
  min-width: 250px !important;
}
